/* .App {
  text-align: center;
} */

.quiz-container {
  width: 100%;
  height: 100%;
}

.question-bg {
  width: 100%;
  height: 100%;
  background-image: url(./assets/BG2.png);
  background-size: cover;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; Adjust the height as needed */


}

/* .headers {
  display: flex;
  flex-direction: row;
  align-items:baseline
} */

.title {

  position: absolute;
  left: 1em;
  top: 1em;

  color: #8D8D8D;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
/* 
  padding-top: 1em;
  padding-left: 2em; */
}

.logo {

  width: 4.5em;
  position: absolute;
  right: 3em;
  top: 2em;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.back-button {
  background-color: #00000013;
  border-radius: 12px;
  border: #a2a297 solid 1px;
  cursor: pointer;
  color: #ffffff;
  font-size: 24px;
  padding: 3px 30px 3px 30px;
  margin-left: 3em;
  letter-spacing: 0.1rem;
}

.back-button:hover {
  background-color: #000000;
}

.next-button {

  background-color: #E8C351;
  border-radius: 12px;
  border: #a2a297 solid 1px;
  cursor: pointer;
  color: #000000;
  font-size: 24px;
  padding: 3px 15px 3px 15px;
  margin-right: 3em;
  letter-spacing: 0.05rem;
}

.next-button:hover {
  background-color: #eecf74;
}

.question-progress-bar {
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  margin-top: 2em;
  
}

.progress-chunk {

  min-width: 6vw;
  min-height: 1em;

  border: 1px solid #5a5a5a;

}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (max-width: 600px) {

  .logo {
    width: 3em;
    position: absolute;
    right: 1em;
    top: 1em;
  }

  .title {
    left: 1em;
    top: 1em;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }

  .buttons-container {
    display: flex;
    flex-direction:column-reverse;
    align-items: center;
    justify-content: space-between;
    min-height: 6em;
    margin-top: -2em;

  }

  .back-button {
    background-color: #00000013;
    border-radius: 12px;
    border: #a2a297 solid 1px;
    cursor: pointer;
    color: #ffffff;
    font-size: 24px;
    padding: 3px 30px 3px 30px;
    margin-left: 0em;
    width: 80vw;

  }

  .next-button {
    margin-right: 0;
    width: 80vw;
  }

}


