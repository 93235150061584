html,
body,
#root {

  font-family: 'Montserrat', sans-serif;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  height: 100%;
  width: 100%;
  box-sizing: border-box;
  color: white;
  background-color: #0C1922;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 }

 button {
  font-family: inherit;
 }

