.question-title {
  color: white;
  animation: fadeIn 1.5s ease-in;
  animation-fill-mode: forwards;
}

.question-container {
 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh; /* Adjust the height as needed */
  margin-left: 4em;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.question-number {
  font-size: 2vw;
  font-weight: 500;
  padding-bottom: 1em;
  animation: fadeIn 1.5s ease-in;
  animation-fill-mode: forwards;

}

.question-row {

  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: 500;
  font-size: 2vh;
}
.question-title {
  max-width: 25em;
  font-weight: 500;
  font-size: 2vw;
  padding-bottom: 1em;
  
}

.radio-buttons {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content:flex-end;
  position: relative;
  right: 10em;
  font-size: 1.5vw;
  font-weight: 500;
}

.radio-item {
  margin-right: .5em;
  margin-bottom: 1em;
}

input[type="radio"] {
  width: 20px;
  height: 20px;

  accent-color: rgb(0, 0, 0);
  margin-right: 1em;
}

@media (min-width: 1800px) {

  .question-title {
    font-size: 35px;
  }

  .question-number {
    font-size: 35px;
  }
  
  .radio-buttons {
    font-size: 25px;
  }
}

@media (max-width: 1000px) {

  .question-title {
    font-size: 3vw;
  }

  .question-number {
    font-size: 3vw;
  }

  .radio-buttons {
    font-size: 2vw;
  }
}

@media screen and (max-width: 600px) {

  .question-container {
    width: 70vw;
  }

  .question-title {
    font-size: 1.2rem;
  }
  .question-number {
    font-size: 1.2rem;
    margin-right: 10px;
  }

  .radio-buttons {
    font-size: 1rem;

    right: 0em;

  }

  input[type="radio"] {
    width: 1em;
    margin-right: .5em;
    accent-color: rgb(0, 0, 0);
  }

  .radio-question {
    font-size: 1.5rem;
  }

  .question-row {
    margin-bottom: 2em;
    margin-top: 2em;
  }
  
}
  






