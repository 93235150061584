/* backgrounds */
/* backgrounds */

div[class^="results-bg"] {
  background-size: cover;
  animation: fadeBackground 1.5s ease-in-out;
  animation-fill-mode: forwards;
  min-height: 100vh;
  background-size: 100% 100%;

}

.results-bg-wolf {
  background-image: url(../../../public/backgrounds/wolf.png);
}

.results-bg-orca {
  background-image: url(../../../public/backgrounds/orca.png);
}

.results-bg-owl {
  background-image: url(../../../public/backgrounds/owl.png);
}

.results-bg-elk {
  background-image: url(../../../public/backgrounds/elk.png);
}

.results-bg-eagle {
  background-image: url(../../../public/backgrounds/eagle.png);


}

.results-bg-bear {
  background-image: url(../../../public/backgrounds/bear.png);
}

.results-bg-lion {
  background-image: url(../../../public/backgrounds/lion.png);
}

.results-bg-dolphin {
  background-image: url(../../../public/backgrounds/dolphin.png);
}

.results-bg-rabbit {
  background-image: url(../../../public/backgrounds/rabbit.png);
}

.results-bg-bee {
  background-image: url(../../../public/backgrounds/bee.png);
}

.results-bg-goose {
  background-image: url(../../../public/backgrounds/goose.png);
}

.results-bg-octopus {
  background-image: url(../../../public/backgrounds/octopus.png);
}

@media (max-width: 1050px) {



  div[class^="results-bg"] {

    animation: fadeBackground 1.5s ease-in-out;
    animation-fill-mode: forwards;
  } 
  

  .results-bg-wolf {
    background-image: url(../../../public/mobile/wolf.png);
  }

  .results-bg-orca {
    background-image: url(../../../public/mobile/orca.png);
  }

  .results-bg-owl {
    background-image: url(../../../public/mobile/owl.png);
  }

  .results-bg-elk {
    background-image: url(../../../public/mobile/elk.png);
  }

  .results-bg-eagle {
    background-image: url(../../../public/mobile/eagle.png);
  }

  .results-bg-bear {
    background-image: url(../../../public/mobile/bear.png);
  }

  .results-bg-lion {
    background-image: url(../../../public/mobile/lion.png);
  }

  .results-bg-dolphin {
    background-image: url(../../../public/mobile/dolphin.png);
  }

  .results-bg-rabbit {
    background-image: url(../../../public/mobile/rabbit.png);
  }

  .results-bg-bee {
    background-image: url(../../../public/mobile/bee.png);
  }

  .results-bg-goose {
    background-image: url(../../../public/mobile/goose.png);
  }

  .results-bg-octopus {
    background-image: url(../../../public/mobile/octopus.png);
  }

  
}



.results-container {
  padding-left: 1.5em;
}

.results-container a {
  font-size: 18px;
  margin-top: 5px;
}


.top-text {

  padding-top: 3em;
  
  max-width: 27em;

  font-size: 20px;
  
}


/* DATA BOXES */
/* DATA BOXES */

.results-boxes-container {

  font-size: 1rem;
  font-weight: 500;

}

.transparent-color-box {
  min-width: 47vw;
  max-width: 47vw;
  margin-top: .7em;
  padding: .5em;
  padding-left: .5em;
  padding-bottom: .5em;
  background-color: #4d79d168;
  border: 2px solid;
  border-radius: 10px;

}

.separator-line {
  border-bottom: calc(400px - 16vw) solid #ffffff;
  width: 2px;
  margin-left: 1em;
  margin-right: 2em;
  align-self: center;
}

.characteristics {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}



.header {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: .3em;
}

.section {
  flex: 1;
  font-size: 13px;
}

ul {
  list-style-type:square;
}




  

li {
  margin-bottom: .5em;
}


.graph-box {
  min-width: 47vw;
  max-width: 47vw;
  margin-top: .7em;
  padding: .7em;
  background-color: #0b0f1968;
  border: #aaaeb7 2px solid;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
}

.graph-container {
  display: grid;
  grid-template-columns: repeat(21, 1fr);
  gap: 3px 0;
  align-items: center;
  list-style-type: none;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  margin-top: .3em;
  margin-left: .5em;
  width: 80%;

  animation: barGrowth 2.5s ease-in-out;
  animation-fill-mode: forwards;

}

.bottom-boxes-container {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: flex-start;
  margin-top: .7em;
  min-width: 47vw;
  max-width: 47vw;

}

.transparent-bottom-box {
  padding: 1em 1em .5em 1em;
  background-color: #0101027e;
  border: #4D78D1 2px solid;
  border-radius: 10px;
  min-width: 15.7vw;
}



@keyframes barGrowth {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}


.graph-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: .25em .5rem;
  margin-top: .5px;
}



.graph-labels > p {
  font-size: 14px;
  padding-top: 1px;
  min-width: 175px;
}
  

@keyframes fadeBackground {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 1800px) {

  div[class^="results-bg"] {
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 1152px) {
  .characteristics {
    flex-direction: column;
    align-items: center;
  }

  .section {
    align-self: flex-start;
  }

  div[class^="results-bg"] {

    min-height: 100vh;
    background-color: black;
  
  }

  .separator-line {
    border-bottom: 2px solid #ffffff;
    width: 100%;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .button-group {
    position: static !important;
    margin-top: 1em !important;
    width: 100% !important;
    margin-left: 0 !important;
  }

  a {
    min-width: 100% !important;
    text-align: center;
  }
  .bottom-boxes-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
  }

  ul {
    list-style-position: inside !important;
  }

  a {
    width: fit-content !important;
  }


}





@media screen and (max-width: 500px) {

  .top-text {
    padding-left: 1em;
  }

  .animal-name {
    text-align: center;
    align-self: flex-start;
    padding-left: .5em;
  }


  .transparent-color-box {
    min-width: 90vw;
    max-width: 90vw;
    margin-left: 0;
  }


  .results-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }

  .section {
   min-width: fit-content !important;
  }

  .graph-box {
    min-width: 90vw;
    max-width: 90vw;
    margin-left: 0;
  }

  .bottom-boxes-container {
    min-width: 90vw;
    max-width: 90vw;
    margin-left: 0;
  }

}