
.landing-container {
  background-image: url(../assets/BG1.png);
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

a {
  font-weight: 500;
}

.landing-title {

  border-radius:  0 200px 200px 0px;

  color: #000000;
  font-weight: 500;
  font-size: 2.5vw;
  letter-spacing: 0.1rem;
  background-color: #e8c251c3;

  /*  */

  width: 60vw;

  padding-top: 1em;
  padding-left: 2em;

  align-self: flex-start;

  margin-bottom: 2em;

  max-width: 1400px;

}

.text-container {
  display: flex;
  flex-direction: column;
  width: 75vw;
}

.landing-container h2 {

  font-weight: 600;
  font-size: 2.8vw;
}

.landing-container h3 {

  font-weight: 500;
  font-size: 1.5vw;
  margin-top: 1em;
}

.landing-container span {

  font-weight: 600;
  color: #e8c251;
}

a:visited {
  text-decoration: none;
  color: #000000;
}

a:link {
  text-decoration: none;
  color: #000000;
}

.landing-container p {

  font-weight: 500;
  font-size: 1vw;
  margin-top: 1em;
}

.landing-logo{

  width: 4.5em;
  position: absolute;
  right: 3em;
  bottom: 6em
  
}

@media (min-width: 1700px) {
  .landing-title {
    
    font-size: 2vw;

    width: 50vw;

  }

  .landing-container h2 {

    font-size: 2.5vw;
  }

  .landing-container h3 {

    font-size: 1.2vw;
  }

  .landing-container p {

    font-size: .8vw;
  }
  
}


@media (max-width: 1000px) {

  .landing-title {

    width: 90vw;

    font-size: 4vw;
    
    position: absolute;
    bottom: 65%;
  }

  .text-container {
    width: 90vw;
    margin-top: 8em;
  }

  .landing-container h2 {

    font-size: 5vw;
  }

  .landing-container h3 {

    font-size: 3vw;
  }

  .landing-container p {

    font-size: 2vw;
  }

  .landing-logo {

    width: 3em;
    position: absolute;
    right: 1em;
    bottom: 4em;
  }


}


@media (max-width: 600px) {

  .landing-container .next-button {
    max-width: 90vw !important;
    width: 90vw !important;
    text-align: center;
  }

  .landing-logo {

    position: relative;
    
    bottom: 0em;

    align-self: flex-end;

    margin-top: 1em;
  }
}

